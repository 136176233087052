define("ln-ember-form-elements/templates/components/form-elements/validation-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Wh6Svfi",
    "block": "{\"symbols\":[\"scope\",\"@icon\",\"&attrs\",\"@errorMessage\",\"@warningMessage\"],\"statements\":[[4,\"let\",[\"FormElementsValidationIcon\"],null,{\"statements\":[[0,\"  \"],[5,\"svg-icon\",[[12,\"class\",[23,1,[]]],[12,\"id\",[23,0,[\"_elementId\"]]],[13,3]],[[\"@name\"],[[28,\"or\",[\"exclamation\",[23,2,[]]],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"ln-tooltip\",[],[[\"@arrow\",\"@id\"],[true,[29,[[23,0,[\"_elementId\"]],\"-validationTooltip\"]]]],{\"statements\":[[0,\"\\n      \"],[7,\"span\",true],[11,\"class\",[29,[[23,1,[]],\"-validationMessage\\n        \",[28,\"if\",[[23,4,[]],[28,\"concat\",[[23,1,[]],\"-validationMessage--error\"],null],[28,\"concat\",[[23,1,[]],\"-validationMessage--warning\"],null]],null],\"\\n      \"]]],[8],[0,\"\\n        \"],[1,[28,\"or\",[[23,4,[]],[23,5,[]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/validation-icon.hbs"
    }
  });

  _exports.default = _default;
});