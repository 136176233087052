define("ln-ember-form-elements/components/form-elements/dropdown-button/dropdown", ["exports", "ln-ember-form-elements/templates/components/form-elements/dropdown-button/dropdown"], function (_exports, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dropdown.default,
    attributeBindings: ['hidden'],
    classNames: [// Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'dropdown', 'form-elements--dropdown-button--dropdown-component'],
    hidden: Ember.computed('isOpen', function () {
      return !this.isOpen;
    })
  });

  _exports.default = _default;
});