define("ln-ember-form-elements/utils/date-helper", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ISO_STRING = void 0;
  _exports.applyTime = applyTime;
  _exports.formatDate = formatDate;
  var ISO_STRING = 0;
  _exports.ISO_STRING = ISO_STRING;

  function formatDate(date) {
    var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD';

    if (date && (0, _moment.default)(date).isValid()) {
      if (format === ISO_STRING) {
        return (0, _moment.default)(date).toISOString();
      }

      return (0, _moment.default)(date).format(format);
    }

    return '';
  }

  function applyTime(date, time) {
    if (date && (0, _moment.default)(date).isValid() && /^\d?\d+:\d+\d+$/.test(time)) {
      return (0, _moment.default)((0, _moment.default)(date).format("YYYY-MM-DD [".concat(time, "] Z")), 'YYYY-MM-DD HH:mm Z').toISOString();
    }

    return date;
  }
});