define("ln-ember-form-elements/templates/components/form-elements/styled-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TQ7WLuw1",
    "block": "{\"symbols\":[\"@autoFocus\",\"@placeholder\"],\"statements\":[[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[11,\"for\",[23,0,[\"inputId\"]]],[8],[1,[23,0,[\"label\"]],false],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[28,\"input\",null,[[\"type\",\"id\",\"value\",\"placeholder\",\"maxlength\",\"autofocus\",\"autocomplete\",\"focus-in\",\"focus-out\",\"wheel\",\"key-press\",\"key-down\",\"key-up\",\"readonly\",\"disabled\"],[[23,0,[\"type\"]],[23,0,[\"inputId\"]],[23,0,[\"inputValue\"]],[23,2,[]],[23,0,[\"maxLength\"]],[23,1,[]],[23,0,[\"autocomplete\"]],[28,\"action\",[[23,0,[]],\"focusIn\"],null],[28,\"action\",[[23,0,[]],\"focusOut\"],null],[28,\"action\",[[23,0,[]],\"wheel\"],null],[23,0,[\"onKeyPress\"]],[23,0,[\"onKeyDown\"]],[23,0,[\"onKeyUp\"]],[23,0,[\"readonlyAttribute\"]],[23,0,[\"disabled\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"maxLength\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"characters-countdown \",[28,\"if\",[[23,0,[\"limitExceeded\"]],\"limit-exceeded\"],null]]]],[8],[1,[23,0,[\"remainingCharacters\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"error-message\"],[8],[1,[23,0,[\"errorMessage\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-input.hbs"
    }
  });

  _exports.default = _default;
});