define("ln-ember-contact-menu/components/micro-menu-item", ["exports", "ln-ember-contact-menu/templates/components/micro-menu-item"], function (_exports, _microMenuItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NO_OP_HREF = Ember.String.htmlSafe('javascript:void(0)');

  var _default = Ember.Component.extend({
    layout: _microMenuItem.default,
    tagName: 'li',
    classNames: ['micro-menu-item', 'micro-menu--item'],
    classNameBindings: ['description:has-description', 'namedClass', 'isDisabled', 'isFake'],
    name: '',
    title: '',
    titleTooltip: undefined,
    description: false,
    descriptionTooltip: undefined,
    hasIcon: true,
    href: Ember.computed({
      get: function get() {
        return NO_OP_HREF;
      },
      set: function set(k, v) {
        return v;
      }
    }),
    target: Ember.computed({
      get: function get() {
        return '_self';
      },
      set: function set(k, v) {
        return v;
      }
    }),
    isDisabled: Ember.computed('onClick', 'href', function () {
      var href = this.get('href');
      return (!href || href === NO_OP_HREF) && typeof this.onClick !== 'function';
    }),
    namedClass: Ember.computed('name', function () {
      var name = this.get('name');
      return name ? "item-".concat(name) : false;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._setTooltipIfOverflown('title');

      this._setTooltipIfOverflown('description');
    },
    _setTooltipIfOverflown: function _setTooltipIfOverflown(fieldName) {
      var _this = this;

      var node = this.element.querySelector(".micro-menu-item--".concat(fieldName));

      if (node && isTextOverflown(node)) {
        Ember.run(function () {
          return _this.set("".concat(fieldName, "Tooltip"), _this.get(fieldName));
        });
      }
    },
    click: function click() {
      if (this.get('isDisabled')) {
        return false;
      }

      if (typeof this.onClick === 'function') {
        return this.onClick();
      }
    }
  });
  /**
   * Checks if the text overflows element boundaries
   *
   * @param {HTMLElement} element
   * @returns {bool}
   */


  _exports.default = _default;

  function isTextOverflown(element) {
    return element.scrollWidth > element.offsetWidth;
  }
});