define("ember-attacher/templates/components/attach-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gKlD93Ho",
    "block": "{\"symbols\":[\"emberPopper\",\"&default\"],\"statements\":[[1,[28,\"unbound\",[[23,0,[\"_parentFinder\"]]],null],false],[4,\"if\",[[28,\"and\",[[23,0,[\"_currentTarget\"]],[28,\"or\",[[28,\"not\",[[23,0,[\"lazyRender\"]]],null],[23,0,[\"_mustRender\"]]],null]],null]],null,{\"statements\":[[4,\"ember-popper\",null,[[\"ariaRole\",\"class\",\"eventsEnabled\",\"id\",\"modifiers\",\"placement\",\"popperContainer\",\"popperTarget\",\"registerAPI\",\"renderInPlace\"],[[23,0,[\"ariaRole\"]],\"ember-attacher\",false,[23,0,[\"id\"]],[23,0,[\"_modifiers\"]],[23,0,[\"placement\"]],[23,0,[\"popperContainer\"]],[23,0,[\"_currentTarget\"]],[28,\"action\",[[23,0,[]],\"_registerAPI\"],null],[23,0,[\"renderInPlace\"]]]],{\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"_class\"]]]]],[11,\"style\",[23,0,[\"_style\"]]],[8],[0,\"\\n      \"],[14,2,[[28,\"hash\",null,[[\"emberPopper\",\"hide\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"hide\"],null]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"arrow\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"x-arrow\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[23,0,[\"animation\"]],\"fill\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"x-circle\",\"\"],[11,\"style\",[23,0,[\"_circleTransitionDuration\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-attacher/templates/components/attach-popover.hbs"
    }
  });

  _exports.default = _default;
});