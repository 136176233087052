define("ln-ember-form-elements/components/form-elements/styled-select", ["exports", "jquery", "ln-ember-form-elements/templates/components/form-elements/styled-select", "ln-ember-form-elements/utils/enforce-array", "ln-ember-form-elements/utils/weak-find", "ln-ember-form-elements/utils/weak-equals", "ln-ember-form-elements/utils/weak-union-by", "ln-ember-form-elements/utils/tabbable-element"], function (_exports, _jquery, _styledSelect, _enforceArray, _weakFind, _weakEquals, _weakUnionBy, _tabbableElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var HELPER_DIV = document.createElement('div');
  /**
   *
   * Renders an styled select.<br> Options can either be passed in as a property
   * or a resource to load options through ajax can be defined<br> If the options
   * get loaded through ajax requests the [API
   * service](https://bitbucket.org/ligadigital/ln-ember-api-service) has to be
   * injected into the component.
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{form-elements/styled-select}}
   * ```
   *
   * ```Handlebars
   * {{form-elements/styled-select
   *   options=allItems
   *   preloadedOptions=preloadedOptions
   *   value=model.type_id
   *   optionValuePath="id"
   *   optionLabelPath="name"
   *   placeholder=(loc "Choose category")
   *   mandatory=true
   *   closeOnScrollContainer=".dialog"
   *   onChange=(action (mut model.type_id) value='value')
   *   onMissingOptions=(action "onMissingOptions")
   * }}
   * ```
   *
   * @class formElements/StyledSelectComponent
   * @extends @ember/component
  */

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    formElements: Ember.inject.service(),
    layout: _styledSelect.default,
    tagName: 'div',
    classNames: [// Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'styled-select-component', 'form-elements--styled-select-component'],
    classNameBindings: ['readonly', 'error:has-error', 'searchIsOpen', 'isOpen', 'isActive:is-active:is-inactive', 'theme', 'themeClass', 'removeIsVisible', 'hasLabel'],
    removeIsVisible: false,
    isActive: true,

    /**
     * The label of the select field
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type String
     * @default null
     */
    label: null,

    /**
     * If this is a truthy value the input field will be set into the error state.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    error: null,

    /**
     * An array of all options that are listed in the select field.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Array}
     * @default null
     */
    options: null,

    /**
     * The value of the current selected option.<br>
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @type {any}
     * @default null
     */
    value: null,

    /**
     * Defines which attribute of an options object should be used as the value of
     * the option.<br>
     * For example if the value is the id of the object the `optionValuePath`
     * should be set to `id`
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    optionValuePath: null,

    /**
     * Defines which attribute of an options object should be used as the label of
     * the option.<br>
     * For example if the attribute `name` should be used as the label
     * `optionLabelPath` should be set to `name`
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    optionLabelPath: null,

    /**
     * If the options are loaded per ajax the `pagingLimit` sets the amount of
     * data to be loaded per page.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Number}
     * @default 25
     */
    pagingLimit: 25,

    /**
     * TotalCount for all options in the select.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Number}
     * @default 0
     */
    totalCount: 0,

    /**
     * If the select is mandatory, there is no button to unset the current
     * selected item.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    mandatory: false,

    /**
     * Defines if the select field is a multiple or single select field.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    multiple: false,

    /**
     * Because of technical limitations of select2 the dropdown won't update the
     * position if the select field is positioned in a scrollable container and
     * the user scrolls within this container.<br>
     * `closeOnScrollContainer` can be set to an jQuery selector string in which
     * case the select dropdown will be closed if the user is scrolling in that
     * container.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    closeOnScrollContainer: null,

    /**
     * **The API service has to be injected into this component if you want to use
     * this feature!**<br>
     *
     * Defines the resource where select2 loads options per ajax requests.
     *
     * ### Example:
     *
     * ```JavaScript
     * { namespace: vdc, uri: '/users', parameters: { param1: 'test' } }
     * ```
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Object}
     * @default null
     * @requires API Service
     */
    optionsResource: null,

    /**
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default 'light'
     */
    theme: null,

    /**
     * Can provide a function, which returns a HTML string, to change the option
     * template.
     *
     * The template function, gets the option passed in as paramter.
     *
     * ### Example 1:**
     *
     * ```JavaScript
     * Controller.extend({
     *
     *   options: [
     *      { id: 1, name: 'Project A', status: 'active' },
     *      { id: 2, name: 'Project B', status: 'inactive' }
     *   ]
     *
     *   optionTemplate: ({ id, name, status }) => `
     *     <span class="status-${status}">${name}</span>
     *   `
     * })
     * ```
     *
     * ```Handlebars
     * {{styled-select
     *   options=options
     *   optionTemplate=optionTemplate
     * }}
     * ```
      * ### Example 2:**
     *
     * You can also pass an action if you need to access `this`.
     *
     * ```JavaScript
     * Controller.extend({
     *
     *   options: [
     *     'Active',
     *     'Inactive'
     *   ]
     *
     *   actions: {
     *     optionTemplate(status) {
     *       const name = this.get('locale').translate(status)
      *       return `
     *         <span class="status-${status}">${name}</span>
     *       `
     *     }
     *   }
     * })
     * ```
     *
     * ```Handlebars
     * {{styled-select
     *   options=options
     *   optionTemplate=(action "optionTemplate")
     * }}
     * ```
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Function}
     * @default null
     */
    optionTemplate: null,

    /**
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {function}
     * @default null
     */
    selectionTemplate: null,

    /**
     * Property to map the value change Event.
     *
     * ```JavaScript
     * onChange({filter, value, selectedOptions})
     * ```
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Function}
     */
    onChange: function onChange() {},

    /**
     * Defines a placeholder to be shown in the select field if there is no option
     * selected.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default 'Select an Option'
     */
    placeholder: Ember.String.loc('Select an Option'),

    /**
     * If the number of options in the select field is higher then the
     * `minimumResultsForSearch` a search field will be shown.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Number}
     * @default 10
     */
    minimumResultsForSearch: Ember.computed.alias('formElements.defaults.minimumResultsForSearch'),

    /**
     * Function to map the missing option on external value change.
     *
     * `onMissingOptions({ filter, value })`
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Function}
     */
    onMissingOptions: function onMissingOptions() {},

    /**
     * Function called when select is opening.
     *
     * `onOpening(select)`
     *
     * Return `false` to prevent opening the select.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Function}
     */
    onOpening: function onOpening() {},

    /**
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default 'Searching...'
     */
    searchingMessage: Ember.String.loc('Searching...'),

    /**
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default 'No results found'
     */
    noResultsMessage: Ember.String.loc('No results found'),
    hasLabel: Ember.computed('label', function () {
      return Boolean(this.get('label'));
    }),
    selectedCount: Ember.computed('internalValues.[]', function () {
      var values = this.get('internalValues');
      return Ember.isArray(values) ? values.length : 0;
    }),
    removeIsAllowed: Ember.computed('_removeIsAllowed', 'mandatory', 'internalValues.length', 'readonly', {
      get: function get() {
        if (this._removeIsAllowed !== undefined) {
          return this._removeIsAllowed;
        }

        return Boolean(!this.get('mandatory') && !this.get('readonly') && this.get('internalValues.length'));
      },
      set: function set(_key, value) {
        this.set('_removeIsAllowed', value);
        return value;
      }
    }),
    themeClass: Ember.computed('theme', function () {
      return "theme-".concat(this.get('theme') || 'light');
    }),
    _select2Placeholder: Ember.computed('multiple', 'placeholder', function () {
      return this.get('multiple') ? null : String(this.get('placeholder'));
    }),
    searchIsOpen: Ember.computed('isOpen', function () {
      if (!this.get('multiple') && this.get('isOpen') && (0, _jquery.default)('select', this.element)) {
        var _this$select2 = this.select2(),
            $dropdown = _this$select2.$dropdown;

        return $dropdown.find('.select2-search__field:visible').length > 0;
      }

      return false;
    }),

    /**
     * Function called to process ajax results.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Function}
     * @param {Array} results
     * @returns {Array}
     */
    onProcessResults: function onProcessResults(results) {
      return results;
    },

    /**
     * Private options list required to maintain the value type and
     * currentSelected Values
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @type {Array}
     * @default null
     */
    internalOptions: null,

    /**
     * List of preloadedOptions for the styled select, the component is watching
     * this property to update itself this is the only way to update the component
     * values from outside.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Array}
     * @default null
     */
    preloadedOptions: null,

    /**
     * defines Select2 behavior on select element (close or not )
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default true
     */
    closeOnSelect: true,

    /**
     * Open select options on focus.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    openOnFocus: false,

    /**
     * Passed to select2 to fix the width of the options list
     *
     * @see https://github.com/ligadigital/select2/commit/c6414e52000eb2a9668fda7e02c3b99cb60ece14
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    dropdownAutoWidth: false,

    /**
     * Set the search query param for the api as it differs between VDC and
     * MyLIGA.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {string}
     * @default search_param  (MyLIGA value)
     */
    searchParam: 'search_param',

    /**
     * TBD
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    animate: false,

    /**
     * If set to true, select will be disabled/readonly
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default null
     */
    readonly: null,

    /**
     * TBD
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default null
     *
     * @deprecated
     */
    filter: null,

    /**
     * @memberof formElements/StyledSelectComponent
     *
     * @instance
     * @private
     *
     * @type {String}
     * @default null
     */
    optionsHash: null,

    /**
     * Should display the count of selected options
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    showCount: false,

    /**
     * This class will be assigned to select2s dropdown element. This is achieved
     * by setting the `dropdownCssClass` option of select2.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String|Function}
     */
    dropdownClass: null,
    internalValues: null,
    isOpen: null,
    isTouchDevice: false,
    _removeIsAllowed: undefined,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }

      this.totalCountSetter();
      this.initLastValues();
      this.set('isTouchDevice', 'ontouchstart' in window);

      this.handleMouseEnter = function () {
        if (_this.get('animate') && !_this.get('isTouchDevice')) {
          var label = (0, _jquery.default)('.multiple-label', _this.element);

          if ((0, _jquery.default)('.full-text', _this.element).length === 0) {
            label.wrapInner('<span class="full-text"></span>');
          }

          var longText = (0, _jquery.default)('.full-text', _this.element);

          if (longText.outerWidth() > label.width()) {
            label.css('text-overflow', 'clip');
            longText.css('margin-left', "".concat(label.width() - longText.outerWidth(), "px"));
          }
        }
      };

      this.handleMouseLeave = function () {
        if (_this.get('animate')) {
          var longText = (0, _jquery.default)('.full-text', _this.element);
          (0, _jquery.default)('.multiple-label', _this.element).one('transitionend', function () {
            (0, _jquery.default)('.multiple-label', _this.element).css('text-overflow', '');
          });
          longText.css('margin-left', '0px');
        }
      };

      (false && !(!this.get('filter')) && Ember.deprecate('[StyledSelectComponent] Filter is deprecated. Use this bind identifiers: `onChange=(action "onChange" "myIdentifier")`', !this.get('filter'), {
        for: 'ln-ember-form-elements',
        id: 'styled-select-filter-attribute',
        since: '3.0.0-beta.115',
        until: '3.0.0'
      }));
    },
    initLastValues: function initLastValues() {
      if (this.get('optionsResource')) {
        return;
      }

      if (Ember.isArray(this.get('options'))) {
        this.set('_lastOptions', Ember.assign([], this.get('options')));
      } else {
        this.set('_lastOptions', []);
      }

      if (Ember.isArray(this.get('value'))) {
        this.set('_lastValue', Ember.assign([], this.get('value')));
      } else {
        this.set('_lastValue', this.get('value'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.isNone(this.get('_lastValue'))) {
        if (Ember.isArray(this.get('value'))) {
          this.set('_lastValue', Ember.assign([], this.get('value')));
        } else {
          this.set('_lastValue', this.get('value'));
        }
      }
    },
    createTemplate: function createTemplate(property) {
      var _this2 = this;

      var template = this.get(property);

      if (typeof template !== 'function') {
        // Default behavior
        return undefined;
      }

      return function (_ref) {
        var text = _ref.text,
            option = _ref.option;

        if (!text && option) {
          // Do not handle preLoadedOptions
          return;
        }

        if (!option) {
          var preloadedOption = _this2.formatOptions(_this2.get('preloadedOptions')).find(function (_ref2) {
            var pText = _ref2.text;
            return pText === text;
          });

          if (preloadedOption) {
            option = preloadedOption.option;
          } else {
            // Do not handle status messages (like "Loading...")
            return text;
          }
        } // Create DOMElement from string


        HELPER_DIV.innerHTML = template(option);
        return HELPER_DIV.childNodes;
      };
    },

    /**
     * Sets totalCount for all types of Arrays.<br>
     * used for initial render before open the select while there is preloadedOptions
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     */
    totalCountSetter: function totalCountSetter() {
      var _this3 = this;

      if (!this.get('multiple')) {
        // For single selects totalCount does not need to be updated, since it is
        // never displayed.
        return;
      }

      Ember.RSVP.resolve(this.get('preloadedOptions')).then(function (preloadedOptions) {
        var preloadedOptionsLength = Ember.get((0, _enforceArray.default)(preloadedOptions), 'length') || 0;

        if (_this3.get('options')) {
          Ember.RSVP.resolve(_this3.get('options')).then(function (_ref3) {
            var length = _ref3.length;

            if (_this3.get('isDestroyed')) {
              return;
            }

            _this3.set('totalCount', length + preloadedOptionsLength);
          });
        } else if (_this3.get('optionsResource')) {
          var _this3$get2 = _this3.get('optionsResource'),
              namespace = _this3$get2.namespace,
              uri = _this3$get2.uri; // FIXME this should not be the default behavior.
          //
          // If you have many Selects on one page, this could add up to a lot of
          // requests. Just to get the total count.
          //
          // => Showing the total count for remote Selects should be optional
          //    and not default!


          _this3.get('api').read(namespace, [uri, {
            paging_offset: 0,
            paging_limit: 0
          }]).then(function (result) {
            if (_this3.get('isDestroyed')) {
              return;
            } // FIXME `preloadedOptionsLength` should not be added, since it is included in `unfiltered_count` or `total_count`


            _this3.set('totalCount', (Ember.get(result, 'meta.unfiltered_count') || Ember.get(result, 'meta.total_count') || 0) + preloadedOptionsLength);
          });
        }
      });
    },
    hasMultipleSelected: Ember.computed('multiple', 'internalValues.[]', function () {
      return this.get('multiple') && (this.get('internalValues.length') || 0) > 0;
    }),
    labelMultipleSelected: Ember.computed('hasMultipleSelected', 'internalValues.[]', 'totalCount', 'showCount', function () {
      if (this.get('showCount')) {
        return this.get('placeholder');
      }

      if (this.get('hasMultipleSelected')) {
        var selected = this.get('internalValues.length') || 0; // TODO This should be configurable and translated.

        return "".concat(selected, " selected of ").concat(this.get('totalCount'));
      }

      return this.get('placeholder');
    }),

    /**
     * All types of options are treated as remote resource. data, transport,
     * processResults are overridden to have the same behavior in select2
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @returns {Object} full select2 adapter
     */
    prepareSelect2AjaxOptions: function prepareSelect2AjaxOptions() {
      var _this4 = this;

      if (!this.get('optionsResource')) {
        return;
      }

      return {
        cache: true,
        delay: 250,
        data: function data(params) {
          return _this4.ajaxProcessParams(params);
        },
        transport: function transport(params, success, failure) {
          return _this4.ajaxRequest(params, success, failure);
        },
        processResults: function processResults(data, params) {
          return _this4.ajaxProcessResults(data, params);
        }
      };
    },
    ajaxProcessParams: function ajaxProcessParams(params) {
      return _defineProperty({
        paging_limit: this.get('pagingLimit'),
        paging_offset: this.get('pagingLimit') * ((params.page || 1) - 1)
      }, this.get('searchParam'), params.term || null);
    },
    ajaxRequest: function ajaxRequest(params, success, failure) {
      var _ref5 = typeof this.get('optionsResource') === 'function' ? this.get('optionsResource')(params.data[this.get('searchParam')]) : this.get('optionsResource'),
          namespace = _ref5.namespace,
          uri = _ref5.uri,
          optionsResourceParameters = _ref5.parameters;

      var request = this.get('api').read(namespace, [uri, _objectSpread(_objectSpread({}, params.data), optionsResourceParameters)]);
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        request.then(function (data) {
          // api service resolves with undefined on aborted request.
          // @see https://bitbucket.org/ligadigital/ln-ember-api-service/src/bb5072392a25c3c9f149ca43ae09cbf2bccc90ce/addon/services/api.js#lines-353
          if (!data) {
            reject();
          } else {
            resolve(data);
          }
        }, reject);
      }).then(success, failure);

      if (request && typeof request.abort === 'function') {
        promise.abort = request.abort.bind(request);
      }

      return promise;
    },
    ajaxProcessResults: function ajaxProcessResults(data, params) {
      var limit = this.get('pagingLimit');
      var page = params.page || 1;
      var totalCount = Ember.get(data, 'meta.unfiltered_count') || Ember.get(data, 'meta.total_count') || Ember.get(data, 'total');
      var records = Ember.get(data, 'result') || data || [];
      var more = typeof totalCount === 'number' ? page * limit < totalCount : records.length >= limit;

      try {
        records = this.get('onProcessResults')(records);
      } catch (error) {
        console.error('onProcessResults', error.stack || error);
      }

      var results = this.formatOptions(records);
      var preloadedOptions = this.formatOptions(this.get('preloadedOptions'));

      if (page === 1) {
        results = (0, _weakUnionBy.default)(preloadedOptions, results);
      } else {
        // TODO is this necessary? It seems expensive.
        results = results.filter(function (item) {
          return !(0, _weakFind.default)(preloadedOptions, item, 'id');
        });
      }

      (0, _weakUnionBy.default)(this.get('internalOptions'), results);
      return {
        pagination: {
          more: more
        },
        // select2 has a problem only for ajax / multiple / unselect  when the option.id is of type number
        // TODO Why is this not done in formatOptions()?
        results: results.map(function (item) {
          return Ember.assign(item, {
            id: String(item.id)
          });
        })
      };
    },

    /**
     * Fill the normal DOM select element with options[selected=true]
     * before initialize the select2 library.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @returns {RSVP.Promise}
     */
    renderSelectOptions: function renderSelectOptions() {
      var _this5 = this;

      if (!this.get('optionsResource')) {
        return Ember.RSVP.resolve();
      }

      return Ember.RSVP.resolve(this.get('preloadedOptions')).then(function (preloadedOptions) {
        var externalValues = (0, _enforceArray.default)(_this5.get('value'));
        preloadedOptions = _this5.formatOptions(preloadedOptions);
        (0, _weakUnionBy.default)(_this5.get('internalOptions'), preloadedOptions); // Render options to Select

        (0, _jquery.default)('select', _this5.element).html(_this5.get('internalOptions').map(function (item) {
          if ((0, _weakFind.default)(externalValues, Ember.get(item, 'id'))) {
            return new Option(Ember.get(item, 'text'), Ember.get(item, 'id'), null, true);
          }
        }));
      }).catch(function (err) {
        return Ember.debug(err);
      });
    },
    onInternalValueChange: function onInternalValueChange() {
      var _this6 = this;

      var selectedValues = (0, _enforceArray.default)((0, _jquery.default)('select', this.element).val());
      var selectedOptions = this.get('internalOptions').filter(function (item) {
        return (0, _weakFind.default)(selectedValues, Ember.get(item, 'id'));
      });
      var values = selectedOptions.map(function (item) {
        return item.id;
      });

      if (!(0, _weakEquals.default)(this.get('internalValues'), values)) {
        this.set('internalValues', values);
        var value = this.get('multiple') ? values : values[0];
        var filter = this.get('filter');

        var deprecatedOptions = function deprecatedOptions() {
          return _this6.unformatOptions(selectedOptions);
        };

        this.get('onChange')({
          value: value,
          selectedOptions: selectedOptions.map(function (_ref6) {
            var option = _ref6.option;
            return option;
          }),

          get filter() {
            (false && !(false) && Ember.deprecate('[StyledSelectComponent] Filter is deprecated. Use this bind identifiers: `onChange=(action "onChange" "myIdentifier")`', false, {
              for: 'ln-ember-form-elements',
              id: 'styled-select-filter-attribute',
              since: '3.0.0-beta.115',
              until: '3.0.0'
            }));
            return filter;
          },

          get options() {
            (false && !(false) && Ember.deprecate('[StyledSelectComponent] options are deprecated use selectedOptions instead', false, {
              for: 'ln-ember-form-elements',
              id: 'styled-select-filter-on-change-options-param',
              since: '3.0.0-beta.115',
              until: '3.0.0'
            }));
            return deprecatedOptions();
          }

        });
      }
    },
    onExternalValueChange: function onExternalValueChange() {
      var externalValues = (0, _enforceArray.default)(this.get('value'));
      var selectedOptions = this.get('internalOptions').filter(function (item) {
        return (0, _weakFind.default)(externalValues, Ember.get(item, 'id'));
      });
      var values = selectedOptions.map(function (item) {
        return item.id;
      });
      (0, _jquery.default)('select', this.element).val(values);
      var selectValues = (0, _enforceArray.default)((0, _jquery.default)('select', this.element).val());

      if (!(0, _weakEquals.default)(externalValues, selectValues)) {
        Ember.debug('value passed is not existing in the options'); // TODO Something is weird here. This seems to happen a lot for resource options.

        var filter = this.get('filter');
        this.get('onMissingOptions')({
          value: externalValues,

          get filter() {
            (false && !(false) && Ember.deprecate('[StyledSelectComponent] Filter is deprecated. Use this bind identifiers: `onChange=(action "onChange" "myIdentifier")`', false, {
              for: 'ln-ember-form-elements',
              id: 'styled-select-filter-attribute',
              since: '3.0.0-beta.115',
              until: '3.0.0'
            }));
            return filter;
          }

        });
        this.set('internalValues', []);
        (0, _jquery.default)('select', this.element).val(null).trigger('change');
      } else {
        this.set('internalValues', values);
        (0, _jquery.default)('select', this.element).val(values).trigger('change');
      }
    },
    valueOrPreloadedChange: function valueOrPreloadedChange() {
      var _this7 = this;

      this.renderSelectOptions().then(function () {
        return _this7.onExternalValueChange();
      });
    },
    valueOrPreloadObserver: Ember.observer('value.[]', 'preloadedOptions.[]', function () {
      if (this.valueDidChange()) {
        Ember.run.once(this, 'valueOrPreloadedChange');
      }
    }),
    valueDidChange: function valueDidChange() {
      var lastValue = this.get('_lastValue');
      var didChange = false;

      if (Ember.isArray(this.get('value'))) {
        didChange = lastValue && this.get('value') && !(0, _weakEquals.default)(lastValue, this.get('value'));
      } else {
        didChange = lastValue !== this.get('value');
      }

      if (Ember.isArray(this.get('value'))) {
        this.set('_lastValue', Ember.assign([], this.get('value')));
      } else {
        this.set('_lastValue', this.get('value'));
      }

      return didChange;
    },

    /**
     * Used to format the options into `[{id, text}]`
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @param {Array} options
     * @returns {Array}
     */
    formatOptions: function formatOptions(options) {
      (false && !(arguments.length === 1) && Ember.assert('formatOptions() paramter `isExternalSource` is not supported anymore. Use unformatOptions()', arguments.length === 1));
      options = (0, _enforceArray.default)(options);
      var valuePath = this.get('optionValuePath') || 'value';
      var labelPath = this.get('optionLabelPath') || 'label';
      return options.map(function (option) {
        if (_typeof(option) !== 'object') {
          return {
            id: option,
            text: option,
            option: option
          };
        }

        return {
          id: Ember.get(option, valuePath),
          text: Ember.get(option, labelPath),
          option: option
        };
      });
    },

    /**
     * Used to format the options into `[{valuePath, labelPath}]`
     *
     * Do not use this. Use this instead:
     *
     * ```JavaScript
     * options.map(({ option }) => option)
     * ```
     *
     * Remove this in `3.0.0` { id: `styled-select-filter-on-change-options-param` }!
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     * @deprecated
     *
     * @param {Array} options
     * @returns {Array}
     */
    unformatOptions: function unformatOptions(options) {
      var valuePath = this.get('optionValuePath') || 'value';
      var labelPath = this.get('optionLabelPath') || 'label';
      return options.map(function (item) {
        var _ref7;

        return _ref7 = {}, _defineProperty(_ref7, valuePath, Ember.get(item, 'id')), _defineProperty(_ref7, labelPath, Ember.get(item, 'text')), _ref7;
      });
    },

    /**
     * load the options and returns back the correct dataObject for Select2
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @returns {Object}
     */
    loadOptions: function loadOptions() {
      var _this8 = this;

      return Ember.RSVP.resolve(this.get('preloadedOptions')).then(function (preloadedOptions) {
        return _this8.formatOptions(preloadedOptions);
      }).then(function (preloadedOptions) {
        if (_this8.get('options')) {
          // Options are passed in as array or promise
          return Ember.RSVP.resolve(_this8.get('options')).then(function (result) {
            return {
              data: (0, _weakUnionBy.default)(preloadedOptions, _this8.formatOptions(result))
            };
          });
        } else if (_this8.get('optionsResource')) {
          // Options have to be loaded from API resource
          return {
            ajax: _this8.prepareSelect2AjaxOptions()
          };
        } else {
          // TODO should this throw an error?
          // Fallback: no options are provided
          return {
            data: []
          };
        }
      }).catch(function (err) {
        Ember.debug(err);
        return {
          data: []
        };
      });
    },
    refreshSelect: function refreshSelect() {
      // TODO Is this necessary? It seems expensive. Can we refresh the select somehow?
      this._removeListeners();

      this._setup();
    },
    _removeListeners: function _removeListeners() {
      (0, _jquery.default)('select.select2-initialized', this.element).off('change').off('select2:open').off('select2:close').off('select2:opening').html('');
    },
    _setup: function _setup() {
      var _this9 = this;

      if (this.get('isDestroyed')) {
        return;
      }

      this.set('internalOptions', []);
      var dataSource;
      this.loadOptions().then(function (_dataSource) {
        if (_this9.get('isDestroyed')) {
          return;
        }

        dataSource = _dataSource;
        return _this9.renderSelectOptions();
      }).then(function () {
        if (_this9.get('isDestroyed')) {
          return;
        }

        _this9.set('internalOptions', Ember.get(dataSource, 'data') || _this9.get('internalOptions'));

        _this9._select2Init(dataSource);

        _this9._onIsActiveChange();

        _this9.onExternalValueChange();
      });
    },
    _select2Init: function _select2Init(dataSource) {
      var _this10 = this;

      (0, _jquery.default)('select', this.element).select2(Ember.assign({
        dropdownCssClass: this.get('dropdownClass'),
        minimumResultsForSearch: this.get('minimumResultsForSearch'),
        placeholder: this.get('_select2Placeholder'),
        closeOnSelect: this.get('closeOnSelect'),
        dropdownAutoWidth: this.get('dropdownAutoWidth'),
        theme: this.get('themeClass'),
        templateResult: this.createTemplate('optionTemplate'),
        templateSelection: this.createTemplate('selectionTemplate'),
        language: {
          noResults: function noResults() {
            return _this10.get('noResultsMessage');
          },
          searching: function searching() {
            return _this10.get('searchingMessage');
          }
        }
      }, dataSource)).addClass('select2-initialized').on('change', function (evt) {
        return _this10.onSelectChange(evt);
      }).on('select2:open', function (evt) {
        return _this10.onSelectOpen(evt);
      }).on('select2:close', function (evt) {
        return _this10.onSelectClose(evt);
      }).on('select2:opening', function (evt) {
        return _this10.onSelectOpening(evt);
      }).on('select2:open', function () {
        if (_this10.get('isDestroyed')) {
          return;
        }

        var _this10$select = _this10.select2(),
            $container = _this10$select.$container,
            $dropdown = _this10$select.$dropdown,
            $selection = _this10$select.$selection;

        var onKeyDown = function onKeyDown(event) {
          return _this10.onInputKeyDown(event);
        };

        $container.find('.select2-search__field').on("keydown.".concat(_this10.elementId), onKeyDown);
        $dropdown.find('.select2-search__field').on("keydown.".concat(_this10.elementId), onKeyDown);
        $selection.on("keydown.".concat(_this10.elementId), onKeyDown);

        var _$dropdown$find = $dropdown.find('.select2-search__field'),
            _$dropdown$find2 = _slicedToArray(_$dropdown$find, 1),
            input = _$dropdown$find2[0];

        if (input) {
          input.focus();
        }
      }).on('select2:close', function () {
        if (_this10.get('isDestroyed')) {
          return;
        }

        var _this10$select2 = _this10.select2(),
            $container = _this10$select2.$container,
            $dropdown = _this10$select2.$dropdown,
            $selection = _this10$select2.$selection;

        $container.find('.select2-search__field').off("keydown.".concat(_this10.elementId));
        $dropdown.find('.select2-search__field').off("keydown.".concat(_this10.elementId));
        $selection.off("keydown.".concat(_this10.elementId));
      });
      Ember.run.next(function () {
        if (_this10.get('readonly')) {
          _this10._onReadonlyChange();
        }
      });
      return;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter);
      this.element.addEventListener('mouseleave', this.handleMouseLeave);

      this._setup();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);

      this._destroy();
    },
    onOptionsChange: Ember.observer('options.[]', 'optionsResource', function () {
      if (!this.get('optionsResource') && !this.optionsDidChange()) {
        return;
      }

      this.refreshSelect();
    }),
    optionsDidChange: function optionsDidChange() {
      var lastOptions = this.get('_lastOptions');
      var didChange = lastOptions && this.get('options') && !(0, _weakEquals.default)(lastOptions, this.get('options'));

      if (Ember.isArray(this.get('options'))) {
        this.set('_lastOptions', Ember.assign([], this.get('options')));
      } else if (this.get('options')) {
        this.set('_lastOptions', [this.get('options')]);
      }

      return didChange;
    },
    focusIn: function focusIn() {
      if (this.get('openOnFocus') && !this.get('ignoreFocus')) {
        Ember.run.debounce(this, this.open, 100, false);
      }
    },
    moveFocus: function moveFocus() {
      var next = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      var _this$select3 = this.select2(),
          $selection = _this$select3.$selection;

      var element = (0, _jquery.default)('.select2-search__field', this.element).get(0) || $selection.get(0);
      var targetElement = next ? (0, _tabbableElement.nextTabbableElementFor)(element) : (0, _tabbableElement.previousTabbableElementFor)(element);
      (0, _jquery.default)(targetElement).focus();
    },
    open: function open() {
      if (!this.select2()) {
        return;
      }

      var _this$select4 = this.select2(),
          $container = _this$select4.$container,
          $dropdown = _this$select4.$dropdown;

      if ($container.is('.select2-container--focus') && !this.select2().isOpen()) {
        this.select2Call('open');
        $dropdown.focus();
      }
    },
    close: function close() {
      var _this$select5 = this.select2(),
          $container = _this$select5.$container;

      $container.blur();
      this.select2Call('close');
    },
    select2: function select2() {
      if (this.get('isDestroyed')) {
        return;
      }

      return (0, _jquery.default)('select', this.element).data('select2');
    },
    select2Call: function select2Call(method) {
      (0, _jquery.default)('select', this.element).select2(method);
    },
    _destroy: function _destroy() {
      if (this.get('closeOnScrollContainer')) {
        var scrollContainer = this.get('closeOnScrollContainer');
        (0, _jquery.default)(scrollContainer).off("scroll.select2-close-".concat(Ember.guidFor(this)));
      }

      (0, _jquery.default)('.full-text', this.element).contents().unwrap();
      (0, _jquery.default)('select.select2-initialized', this.element).select2('destroy');
    },
    _onIsActiveChange: Ember.observer('isActive', function () {
      var _this11 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        if ((0, _jquery.default)('select', _this11.element)) {
          (0, _jquery.default)('select', _this11.element).prop('disabled', !_this11.get('isActive'));
        }
      });
    }),
    _onReadonlyChange: Ember.observer('readonly', function () {
      if (!this.isDestroyed) {
        (0, _jquery.default)('select.select2-initialized', this.element).prop('disabled', this.get('readonly'));
      }
    }),
    onSelectChange: function onSelectChange()
    /* evt */
    {
      var _this12 = this;

      this.set('ignoreFocus', true);
      Ember.run(function () {
        return _this12.onInternalValueChange();
      });
      Ember.run.later(function () {
        return _this12.get('isDestroyed') || _this12.set('ignoreFocus', false);
      }, 500);
    },
    onSelectOpening: function onSelectOpening() {
      var _this13 = this;

      Ember.run(function () {
        if (_this13.get('isDestroyed')) {
          return;
        }

        if (_this13.get('closeOnScrollContainer')) {
          var scrollContainer = _this13.get('closeOnScrollContainer');

          (0, _jquery.default)(scrollContainer).one("scroll.select2-close-".concat(Ember.guidFor(_this13)), function () {
            _this13.select2Call('close');
          });
        }
      }); // TODO document: selectOpening

      if (this.attrs.selectOpening) {
        (false && !(false) && Ember.deprecate('[StyledSelectComponent] selectOpening is deprecated use onOpening instead', false, {
          for: 'ln-ember-form-elements',
          id: 'styled-select-select-opening',
          since: '3.0.0-beta.115',
          until: '3.0.0'
        }));
        return this.attrs.selectOpening(this) !== false;
      }

      return this.onOpening(this) !== false;
    },
    onSelectOpen: function onSelectOpen() {
      var _this14 = this;

      // TODO run() and later()? I do not think we need both.
      Ember.run(function () {
        return Ember.run.later(function () {
          return _this14.get('isDestroyed') || _this14.set('isOpen', true);
        });
      });
    },
    onSelectClose: function onSelectClose() {
      var _this15 = this;

      Ember.run(function () {
        if (_this15.get('isDestroyed')) {
          return;
        }

        Ember.run.later(function () {
          return _this15.get('isDestroyed') || _this15.set('isOpen', false);
        });
        (0, _jquery.default)('input', _this15.element).blur();
      });
    },
    onInputKeyDown: function onInputKeyDown(_ref8) {
      var key = _ref8.key,
          shiftKey = _ref8.shiftKey;

      switch (key) {
        case 'Tab':
          return this.onInputTabKeyDown(shiftKey);

        case 'Enter':
          return this.onInputEnterKeyDown(shiftKey);

        case 'Escape':
          return this.onInputEscapeKeyDown(shiftKey);
      }
    },
    onInputTabKeyDown: function onInputTabKeyDown(shiftKey) {
      if (this.get('closeOnSelect')) {
        this.close();
        this.moveFocus(!shiftKey);
      }
    },
    onInputEnterKeyDown: function onInputEnterKeyDown(shiftKey) {
      if (this.get('closeOnSelect')) {
        this.moveFocus(!shiftKey);
      }
    },
    onInputEscapeKeyDown: function onInputEscapeKeyDown() {
      (0, _jquery.default)(document.activeElement).blur();
      this.close();
    },
    actions: {
      removeAll: function removeAll() {
        if (this.get('readonly')) {
          return;
        }

        (0, _jquery.default)('select', this.element).val(null).change();
        this.onInternalValueChange();
        this.set('removeIsVisible', false);
      },
      removeMouseEnter: function removeMouseEnter() {
        if (this.get('readonly')) {
          return;
        }

        this.set('removeIsVisible', true);
      },
      removeMouseLeave: function removeMouseLeave() {
        this.set('removeIsVisible', false);
      }
    }
  });

  _exports.default = _default;
});