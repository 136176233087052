define("ln-ember-form-elements/templates/components/form-elements/expanding-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nRONbAXf",
    "block": "{\"symbols\":[\"@label\",\"@maxLength\",\"@value\",\"@readonly\",\"@placeholder\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[7,\"label\",true],[11,\"for\",[29,[[23,0,[\"elementId\"]],\"-textarea\"]]],[8],[1,[23,1,[]],false],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"textarea-wrapper\"],[8],[0,\"\\n  \"],[1,[28,\"textarea\",null,[[\"id\",\"placeholder\",\"readonly\",\"value\",\"maxlength\",\"focus-in\",\"focus-out\",\"key-up\"],[[28,\"concat\",[[23,0,[\"elementId\"]],\"-textarea\"],null],[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]],[28,\"action\",[[23,0,[]],\"focusIn\"],null],[28,\"action\",[[23,0,[]],\"focusOut\"],null],[28,\"action\",[[23,0,[]],\"keyUp\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"characters-countdown \",[28,\"if\",[[23,0,[\"limitExceeded\"]],\"limit-exceeded\"],null]]]],[8],[1,[23,0,[\"remainingCharacters\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/expanding-textarea.hbs"
    }
  });

  _exports.default = _default;
});