define("ln-ember-contact-menu/templates/components/micro-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y8EHnzQ9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"microMenu\",\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"micro-menu--overlay\"],[10,\"tabindex\",\"0\"],[11,\"onclick\",[23,0,[\"_close\"]]],[8],[9],[0,\"\\n\\n\"],[4,\"attach-popover\",null,[[\"class\",\"isShown\",\"hideOn\",\"popperTarget\",\"onChange\",\"popperContainer\"],[[28,\"concat\",[\"micro-menu--container\",[28,\"if\",[[23,0,[\"isNorthern\"]],\" is-northern\",\"\"],null],[28,\"if\",[[23,0,[\"isRendered\"]],\" is-rendered\",\"\"],null]],null],true,\"escapekey\",[23,0,[\"targetElement\"]],[23,0,[\"_onChangePopoverVisibility\"]],[23,0,[\"element\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,0,[\"component\"]]],[[\"didActivateItem\"],[[23,0,[\"_didActivateItem\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-contact-menu/templates/components/micro-menu.hbs"
    }
  });

  _exports.default = _default;
});