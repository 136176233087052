define("ln-ember-form-elements/templates/components/form-elements/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qb0/3XAH",
    "block": "{\"symbols\":[\"scope\",\"NumberInput\",\"@appendElement\",\"@autocomplete\",\"@autoFocus\",\"@disabled\",\"@error\",\"@label\",\"@maxLength\",\"@name\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@value\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@min\",\"@max\",\"@scale\",\"@title\",\"@warning\",\"@class\",\"&attrs\"],\"statements\":[[4,\"let\",[\"FormPrice\",[28,\"component\",[\"form-elements/number\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,2,[]],[[12,\"class\",[29,[[23,1,[]],\" \",[23,26,[]]]]],[13,27]],[[\"@appendElement\",\"@autocomplete\",\"@autofocus\",\"@disabled\",\"@error\",\"@label\",\"@maxlength\",\"@name\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@value\",\"@unit\",\"@unitSeparator\",\"@placeUnitBefore\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@min\",\"@max\",\"@padFractionalZeros\",\"@normalizeZeros\",\"@scale\",\"@title\",\"@warning\"],[[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,14,[]],[23,0,[\"formatSettings\",\"currencySign\"]],\" \",[23,0,[\"formatSettings\",\"isSignBefore\"]],[23,15,[]],[23,16,[]],[23,17,[]],[23,18,[]],[23,19,[]],[23,20,[]],[23,21,[]],[23,22,[]],[23,0,[\"_padFractionalZeros\"]],false,[23,23,[]],[23,24,[]],[23,25,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/price.hbs"
    }
  });

  _exports.default = _default;
});