define("ln-ember-form-elements/components/form-elements/date-range-input", ["exports", "moment", "ln-ember-form-elements/components/form-elements/date-range-input-base", "ln-ember-form-elements/templates/components/form-elements/date-range-input", "ln-ember-form-elements/utils/date-helper"], function (_exports, _moment, _dateRangeInputBase, _dateRangeInput, _dateHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   *
   * ### Value
   *
   * The value is a object in the format `{ start: 'YYYY-MM-DD', end: "YYYY-MM-DD" }` or `null`.
   *
   * If `allowHalfDays` is set to `true`, the range object is extended by two
   * additional attributes `startIsHalfDay` and `endIsHalfDay`:
   *
   * `{ start: 'YYYY-MM-DD', startIsHalfDay: false, end: "YYYY-MM-DD", endIsHalfDay: false }`
   *
   * ### Examples
   *
   * **Basic usage:**
   *
   * ```Handlebars
   * {{form-elements/date-range-input
   *   value=dateValue
   *   onChange=(action "onDateChange")
   * }}
   * ```
   *
   * ```JavaScript
   * Component.extend({
   *   actions: {
   *     onDateChange(value) {
   *       this.set('dateValue', value)
   *     }
   *   }
   * })
   * ```
   *
   *
   * ### Demo
   *
   * <iframe src="showcase/index.html#/demo/date-range-input" style="width: 360px;border: 1px solid black;height: 410px;padding: 20px;"></iframe>
   *
   * @class formElements/DateRangeInputComponent
   * @extends formElements/DateRangeInputBaseComponent
   */
  var _default = _dateRangeInputBase.default.extend({
    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {object}
     * @property {string} start -  Format: `YYYY-MM-DD`
     * @property {boolean} startIsHalfDay - Only defined if `allowHalfDays` is `true`
     * @property {string} end -  Format: `YYYY-MM-DD`
     * @property {boolean} endIsHalfDay - Only defined if `allowHalfDays` is `true`
     * @default null
     */
    value: null,

    /**
     * Allow the user to select half days for start or end dates. If set to
     * `true`, the range object is extended by two additional attributes
     * `startIsHalfDay` and `endIsHalfDay`.
     *
     * @memberof formElements/DateRangeInputComponent
     * @instance
     *
     * @type {Boolean}
     */
    allowHalfDays: false,
    layout: _dateRangeInput.default,
    classNames: ['form-elements--date-range-input-component'],
    classNameBindings: ['error:has-error'],
    error: Ember.computed.or('startError', 'endError'),
    halfDayStartDisabled: Ember.computed('value', function () {
      var _ref = this.get('range') || {},
          start = _ref.start;

      return !start;
    }),
    halfDayEndDisabled: Ember.computed('halfDayStartDisabled', 'value', function () {
      var _ref2 = this.get('range') || {},
          start = _ref2.start,
          end = _ref2.end;

      return this.get('halfDayStartDisabled') || (0, _moment.default)(start).isSame(end, 'day');
    }),
    range: Ember.computed('value.{start,end,startIsHalfDay,endIsHalfDay}', function () {
      return this.formatRange(this.get('value'));
    }),
    actions: {
      onCalendarChange: function onCalendarChange(value) {
        var _ref3 = this.get('range') || {},
            start = _ref3.start,
            end = _ref3.end,
            startIsHalfDay = _ref3.startIsHalfDay;

        if (start && end && start !== end || !start) {
          start = this.normalizeDate(value);
          end = null;
          startIsHalfDay = false;
        } else {
          end = this.normalizeDate(value);
        }

        this.triggerChange({
          start: start,
          end: end,
          startIsHalfDay: startIsHalfDay
        });
      },
      onInputChange: function onInputChange(type, value) {
        if (!value) {
          if (type === 'start') {
            return this.triggerChange(null);
          }
        }

        var range = Object.assign({}, this.get('range'), _defineProperty({}, type, this.normalizeDate(value)));
        var formatDate = this.get('formElements.defaults.formatDate');
        var isRawValueValid = (0, _moment.default)(value, formatDate, true).isValid();

        if (isRawValueValid && this.isValidInput(range)) {
          this.triggerChange(range);
        }
      },
      onHalfDayChange: function onHalfDayChange(type, value) {
        var range = Object.assign({}, this.get('range'), _defineProperty({}, type, value));
        this.triggerChange(range);
      }
    },
    getVisibleDate: function getVisibleDate() {
      return (0, _moment.default)(this.get('range.start') || undefined).format('YYYY-MM-DD');
    },
    isValidInput: function isValidInput(value) {
      var _ref4 = value || {},
          start = _ref4.start,
          end = _ref4.end;

      return (0, _moment.default)(start, 'YYYY-MM-DD', true).isValid() && (Ember.isNone(end) || (0, _moment.default)(end, 'YYYY-MM-DD', true).isValid());
    },
    isValid: function isValid(value) {
      var _ref5 = value || {},
          start = _ref5.start,
          end = _ref5.end;

      return (0, _moment.default)(start, 'YYYY-MM-DD', true).isValid() && (0, _moment.default)(end, 'YYYY-MM-DD', true).isValid();
    },
    isValueEmpty: function isValueEmpty(value) {
      var _ref6 = value || {},
          start = _ref6.start,
          end = _ref6.end;

      return Ember.isNone(start) && Ember.isNone(end);
    },
    triggerChange: function triggerChange(range) {
      this.get('onChange')(this.formatRange(range));
    },
    formatDate: function formatDate(date) {
      return (0, _dateHelper.formatDate)(date, this.get('formElements.defaults.formatDate'));
    },
    formatRange: function formatRange(range) {
      var _ref7 = range || {},
          start = _ref7.start,
          startIsHalfDay = _ref7.startIsHalfDay,
          end = _ref7.end,
          endIsHalfDay = _ref7.endIsHalfDay;

      if (!range || !start) {
        return null;
      }

      var startObj = {
        date: start,
        half: Boolean(startIsHalfDay)
      };
      var endObj = {
        date: end,
        half: Boolean(endIsHalfDay)
      };

      if (!end || start === end) {
        endObj = startObj;
      }

      var _sort = [startObj, endObj].sort(function (_ref8, _ref9) {
        var a = _ref8.date;
        var b = _ref9.date;
        return (0, _moment.default)(a).isAfter(b) ? 1 : -1;
      });

      var _sort2 = _slicedToArray(_sort, 2);

      startObj = _sort2[0];
      endObj = _sort2[1];

      var d = function d(obj) {
        return obj && obj.date ? obj.date : null;
      };

      var h = function h(obj) {
        return obj && obj.date ? Boolean(obj.half) : null;
      };

      if (!this.get('allowHalfDays')) {
        return {
          start: d(startObj),
          end: d(endObj)
        };
      }

      return {
        start: d(startObj),
        startIsHalfDay: h(startObj),
        end: d(endObj),
        endIsHalfDay: h(endObj)
      };
    }
  });

  _exports.default = _default;
});