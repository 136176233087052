define("ln-ember-form-elements/components/form-elements/option-button", ["exports", "ln-ember-form-elements/templates/components/form-elements/option-button"], function (_exports, _optionButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * Renders an option button.<br>
   *
   * ### Example Simple Options:
   *
   * ```JavaScript
   * Component.extend({
   *   options: [1, 2, 3],
   *   value: null,
   *   actions: {
   *     setValue(value) {
   *       this.set('value', value);
   *     }
   *   }
   * })
   * ```
   *
   * ```Handlebars
   * {{form-elements/option-button
   *   options=options
   *   action=(action "setValue")}}
   * ```
   *
   * ### Example Object Options:
   *
   * ```JavaScript
   * Component.extend({
   *   options: [
   *     { name: 'One', value: 1 },
   *     { name: 'Two', value: 2 }
   *   value: null,
   *   actions: {
   *     setValue(value, option) {
   *       this.set('value', value);
   *     }
   *   }
   * })
   * ```
   *
   * ```Handlebars
   * {{form-elements/option-button
   *   options=options
   *   optionValuePath="value"
   *   optionLabelPath="name"
   *   action=(action "setValue")}}
   * ```
   *
   * @class formElements/OptionButtonComponent
   * @extends @ember/component
   */
  var _default = Ember.Component.extend({
    formElements: Ember.inject.service(),
    layout: _optionButton.default,
    classNames: [// Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'option-button-component', 'form-elements--option-button-component'],

    /**
     * Defines which attribute of an options object should be used as the value of the option.<br>
     * For example if the value is the id of the object the  `optionValuePath` should be set to `content.id`
     *
     * @memberof formElements/OptionButtonComponent
     * @instance
     *
     * @type String
     * @default null
     */
    optionValuePath: null,

    /**
     * Defines which attribute of an options object should be used as the label of the option.<br>
     * For example if the attribute `name` should be used as the label  `optionLabelPath` should be set to `content.name`
     *
     * @memberof formElements/OptionButtonComponent
     * @instance
     *
     * @type String
     * @default null
     */
    optionLabelPath: null,

    /**
     * Theme can be "light" or "dark".
     *
     * @memberof formElements/OptionButtonComponent
     * @instance
     *
     * @type string
     * @default "light"
     */
    theme: null,

    /**
     * The label of option button
     *
     * @memberof formElements/OptionButtonComponent
     * @instance
     *
     * @type String
     * @default null
     */
    label: null,

    /**
     * Actions to set the value. Arguments are `value` and `option`.
     *
     * @memberof formElements/OptionButtonComponent
     * @instance
     *
     * @type {function}
     */
    action: function action() {},
    highlightTheme: Ember.computed('theme', function () {
      return "".concat(this.get('theme'), "-highlight");
    }),
    renderOptions: Ember.computed('value', 'optionLabelPath', 'optionValuePath', 'options.[]', function () {
      var _this = this;

      var options = this.get('options') || [];
      return options.map(function (option) {
        var value = option;
        var valuePath = (_this.get('optionValuePath') || '').replace(/^content\.?/, '');
        var labelPath = (_this.get('optionLabelPath') || '').replace(/^content\.?/, '');

        if (valuePath) {
          value = Ember.get(option, valuePath);
        }

        var label = labelPath ? Ember.get(option, labelPath) : value;
        return {
          value: value,
          label: label,
          active: value === _this.get('value'),
          option: option
        };
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
    },
    actions: {
      setValue: function setValue(_ref) {
        var value = _ref.value,
            option = _ref.option;
        this.get('action')(value, option);
      }
    }
  });

  _exports.default = _default;
});