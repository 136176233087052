define("ln-ember-form-elements/components/form-elements/styled-input", ["exports", "ln-ember-form-elements/templates/components/form-elements/styled-input", "jquery"], function (_exports, _styledInput, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * Renders an styled input field.<br>
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{form-elements/styled-input}}
   * ```
   *
   * ```Handlebars
   * {{form-elements/styled-input value="Input text" maxLength=30}}
   * ```
   *
   * @class formElements/StyledInputComponent
   * @extends @ember/component
   */
  var _default = Ember.Component.extend({
    formElements: Ember.inject.service(),
    layout: _styledInput.default,

    /**
     * Fired when the input value changes.
     *
     * **If the `onChange` handler is set, the value does not get updated by the
     * StyledInputComponent anymore.**
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type {function}
     */
    onChange: null,

    /**
     * The value of the input field
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type String
     * @default null
     */
    value: null,

    /**
     * The type of the input field
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type String
     * @default "text"
     */
    type: 'text',

    /**
     * The maximal length of characters that can be entered into the input field.<br>
     * If max length is `null` or another falsy value then there is no limit.
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type Number
     * @default null
     */
    maxLength: null,

    /**
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type String
     * @default light
     */
    theme: null,

    /**
     * If set to true the input field is readonly
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    readonly: false,

    /**
     * If set to true the input field is disabled
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    disabled: false,

    /**
     * The label of the input field
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type String
     * @default null
     */
    label: null,

    /**
     * If `true`, prevents mouse wheel from changing the value of number input
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    stopNumberScroll: false,

    /**
     * Fired on an focus-in event
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type {function}
     */
    onFocusIn: function onFocusIn() {},

    /**
     * Fired on an key-press event
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type {function}
     */
    onKeyPress: function onKeyPress() {},

    /**
     * Fired on an key-down event
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type {function}
     */
    onKeyDown: function onKeyDown() {},

    /**
     * Fired on an key-up event
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type {function}
     */
    onKeyUp: function onKeyUp() {},

    /**
     * Fired on an focus-out event
     *
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type {function}
     */
    onFocusOut: function onFocusOut() {},

    /**
     * @memberof formElements/StyledInputComponent
     * @instance
     *
     * @type String
     * @default 'off'
     */
    autocomplete: 'off',
    classNames: [// Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'styled-input-component', 'form-elements--styled-input-component'],
    classNameBindings: ['hasFocus', 'value:has-content', 'maxLength:has-max-length', 'error:has-error', 'themeClass'],
    limitExceeded: Ember.computed.lt('remainingCharacters', 0),
    inputId: Ember.computed(function () {
      return "".concat(Ember.guidFor(this), "-styled-input-component");
    }),
    // Can't be false... See: https://github.com/emberjs/ember.js/issues/11828
    readonlyAttribute: Ember.computed('readonly', function () {
      return Boolean(this.get('readonly')) || undefined;
    }),
    inputValue: Ember.computed('value', {
      get: function get() {
        return this.get('value');
      },
      set: function set(prop, value) {
        (false && !(Boolean(this.get('onChange'))) && Ember.deprecate('[StyledInputComponent] Data binding is deprecated. onChange action should be handled.', Boolean(this.get('onChange')), {
          for: 'ln-ember-form-elements',
          id: 'styled-input-component-data-binding',
          since: '3.0.0-beta.115',
          until: '3.0.0'
        }));

        if (this.get('readonly')) {
          return;
        }

        if (this.get('onChange')) {
          this.get('onChange')(value);
        } else {
          this.set('value', value);
        }

        return value;
      }
    }),
    themeClass: Ember.computed('theme', function () {
      return "theme-".concat(this.get('theme') || 'light');
    }),
    errorMessage: Ember.computed('error', function () {
      var error = this.get('error');

      if (error && typeof error === 'string') {
        return error;
      }
    }),
    remainingCharacters: Ember.computed('maxLength', 'value.length', function () {
      return this.get('maxLength') - (this.get('value.length') || 0);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
    },
    didInsertElement: function didInsertElement() {
      // input autofocus doesn't work on re-renders
      // see: https://github.com/emberjs/ember.js/issues/13391
      if (this.get('autoFocus')) {
        (0, _jquery.default)('input', this.element).focus();
      }
    },
    actions: {
      focusIn: function focusIn() {
        this.set('hasFocus', true);
        this.get('onFocusIn')();
      },
      focusOut: function focusOut() {
        this.set('hasFocus', false);
        this.get('onFocusOut')(this.get('inputValue'));

        if (!this.get('readonly')) {
          this.notifyPropertyChange('value');
        }
      },
      wheel: function wheel(event) {
        if (this.get('type') === 'number' && this.get('stopNumberScroll')) {
          event.preventDefault();
        }
      }
    }
  });

  _exports.default = _default;
});