define("ln-ember-form-elements/templates/components/form-elements/percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Ve+dbsT",
    "block": "{\"symbols\":[\"scope\",\"NumberInput\",\"@appendElement\",\"@autocomplete\",\"@autoFocus\",\"@disabled\",\"@error\",\"@label\",\"@max\",\"@maxLength\",\"@min\",\"@name\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@padFractionalZeros\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@shouldRound\",\"@title\",\"@value\",\"@warning\",\"&attrs\",\"@class\"],\"statements\":[[4,\"let\",[\"FormPercent\",[28,\"component\",[\"form-elements/number\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,2,[]],[[13,27],[12,\"class\",[29,[[23,28,[]],\" \",[23,1,[]]]]]],[[\"@appendElement\",\"@autocomplete\",\"@autofocus\",\"@deserialize\",\"@disabled\",\"@error\",\"@inputScale\",\"@label\",\"@max\",\"@maxlength\",\"@min\",\"@name\",\"@normalizeZeros\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@padFractionalZeros\",\"@placeholder\",\"@placeUnitBefore\",\"@prependElement\",\"@readonly\",\"@scale\",\"@serialize\",\"@shouldRound\",\"@title\",\"@unit\",\"@unitSeparator\",\"@value\",\"@warning\"],[[23,3,[]],[23,4,[]],[23,5,[]],[23,0,[\"_deserialize\"]],[23,6,[]],[23,7,[]],[23,0,[\"_inputScale\"]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],false,[23,13,[]],[23,14,[]],[23,15,[]],[23,16,[]],[23,17,[]],[23,18,[]],[23,19,[]],[23,20,[]],false,[23,21,[]],[23,22,[]],[23,0,[\"_scale\"]],[23,0,[\"_serialize\"]],[23,23,[]],[23,24,[]],[23,0,[\"_unit\"]],[23,0,[\"_unitSeparator\"]],[23,25,[]],[23,26,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/percent.hbs"
    }
  });

  _exports.default = _default;
});