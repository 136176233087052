define("ln-ember-form-elements/templates/components/form-elements/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WyBpSdVo",
    "block": "{\"symbols\":[\"scope\",\"MaskedInput\",\"@appendElement\",\"@autocomplete\",\"@autoFocus\",\"@label\",\"@disabled\",\"@error\",\"@maxLength\",\"@name\",\"@prependElement\",\"@readonly\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@title\",\"@warning\",\"@class\",\"&attrs\"],\"statements\":[[4,\"let\",[\"FormNumber\",[28,\"component\",[\"form-elements/-masked-input\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,2,[]],[[12,\"class\",[29,[[23,1,[]],\" \",[23,20,[]]]]],[13,21]],[[\"@appendElement\",\"@autocomplete\",\"@autofocus\",\"@label\",\"@disabled\",\"@error\",\"@maxlength\",\"@name\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@value\",\"@options\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@onCopy\",\"@title\",\"@warning\"],[[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,0,[\"_placeholder\"]],[23,11,[]],[23,12,[]],[23,0,[\"_value\"]],[23,0,[\"maskOptions\"]],[23,0,[\"_onAccept\"]],[23,13,[]],[23,14,[]],[23,15,[]],[23,16,[]],[23,17,[]],[23,0,[\"_onCopy\"]],[23,18,[]],[23,19,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/number.hbs"
    }
  });

  _exports.default = _default;
});