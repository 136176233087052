define("ln-ember-form-elements/templates/components/flash-messages/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KwDRf2V9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[23,0,[\"message\",\"text\"]],false],[9],[0,\"\\n\\n\"],[7,\"button\",true],[10,\"class\",\"close-message\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"removeMessage\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n  \"],[1,[28,\"svg-icon\",[\"exit\"],[[\"class\"],[\"Button-icon\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/flash-messages/flash-message.hbs"
    }
  });

  _exports.default = _default;
});