define("ln-ember-form-elements/components/form-elements/radio-button", ["exports", "ln-ember-form-elements/templates/components/form-elements/radio-button"], function (_exports, _radioButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders a single radio button
   *
   * ### Examples:
   *
   *  this a boolean example usage for the radio-button-component
   *
   * ```Handlebars
   *  {{form-elements/radio-button name="enabled" label="Yes" value=model.enabled buttonValue="true"}}
   *  {{form-elements/radio-button name="enabled" label="No" value=model.enabled buttonValue="false"}}
   * ```
   * @class formElements/RadioButtonComponent
   * @extends @ember/component
  */
  var _default = Ember.Component.extend({
    formElements: Ember.inject.service(),
    layout: _radioButton.default,
    tagName: 'div',
    classNames: [// Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'radio-button-component', 'form-elements--radio-button-component'],

    /**
     * Change action handler.
     *
     * ### Example:**
     *
     * ```JavaScript
     * Controller.extend({
     *   actions: {
     *     change(value) {
     *       console.log('Radio Button Value: ', value);
     *     }
     *   }
     * })
     * ```
     *
     * ```Handlebars
     * {{form-elements/radio-button onChange=(action "change"}}
     * ```
     *
     *
     * @memberof formElements/RadioButtonComponent
     * @instance
     *
     * @type {function}
     * @default null
     */
    onChange: null,

    /**
     * buttonValue is the value of a single radio-button value
     * buttonValue will be passed to the value when the use click on the button
     *
     * @memberof formElements/RadioButtonComponent
     * @instance
     *
     * @type String
     * @default null
     */
    buttonValue: null,

    /**
     * group radio-button value (actual value of the input)
     * it holds the selected value in the button group
     * @memberof formElements/RadioButtonComponent
     * @instance
     *
     * @type String
     * @default null
     */
    value: null,

    /**
     * computed property for the checked state of the button
     *
     * @memberof formElements/RadioButtonComponent
     * @instance
     *
     * @type boolean
     */
    checked: Ember.computed('value', 'buttonValue', function () {
      return String(this.get('value')) === String(this.get('buttonValue'));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
    },
    click: function click() {
      this.handleValueChange();
    },
    touchEnd: function touchEnd() {
      this.handleValueChange();
    },
    handleValueChange: function handleValueChange() {
      var value = this.get('buttonValue');

      if (this.get('onChange')) {
        this.get('onChange')(value);
      } else {
        this.set('value', value);
        (false && !(Boolean(this.get('onChange'))) && Ember.deprecate('[RadioButtonComponent] Data binding is deprecated. onChange action should be handled.', Boolean(this.get('onChange')), {
          for: 'ln-ember-form-elements',
          id: 'radio-button-component-data-binding',
          since: '3.0.0-beta.115',
          until: '3.0.0'
        }));
      }
    }
  });

  _exports.default = _default;
});