define("ln-ember-form-elements/components/form-elements/date-range-input-base", ["exports", "moment", "ln-ember-form-elements/components/form-elements/date-input-base", "ln-ember-form-elements/templates/components/form-elements/date-range-input"], function (_exports, _moment, _dateInputBase, _dateRangeInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * @class formElements/DateRangeInputBaseComponent
   * @extends formElements/DateInputBaseComponent
   * @abstract
   */
  var _default = _dateInputBase.default.extend({
    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    startLabel: null,

    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    endLabel: null,

    /**
     * If this is a truthy value the input field will be set into the error state.
     *
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    startError: null,

    /**
     * If this is a truthy value the input field will be set into the error state.
     *
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    endError: null,
    classNames: ['form-elements--date-range-input-base-component'],
    layout: _dateRangeInput.default,
    date: null,

    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default "Select a date"
     */
    startPlaceholder: 'Select a date',

    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default "Select a date"
     */
    endPlaceholder: 'Select a date',
    displayValue: Ember.computed('range.{start,end}', 'value.{start,end}', 'showDropDown', {
      get: function get() {
        var _ref = this.get('value') || {},
            start = _ref.start,
            end = _ref.end;

        return {
          start: this.formatDate(start),
          end: this.formatDate(end)
        };
      },
      set: function set(key, value) {
        return value;
      }
    }),
    range: Ember.computed('value.{start,end}', function () {
      var _ref2 = this.get('value') || {},
          start = _ref2.start,
          end = _ref2.end;

      if (!start) {
        return {
          start: null,
          end: null
        };
      }

      if (!end) {
        end = start;
      }

      var _sort = [start, end].sort(function (a, b) {
        return (0, _moment.default)(a).isAfter(b);
      });

      var _sort2 = _slicedToArray(_sort, 2);

      start = _sort2[0];
      end = _sort2[1];
      return {
        start: start,
        end: end
      };
    })
  });

  _exports.default = _default;
});