define("ln-ember-form-elements/components/form-elements/dropdown-button", ["exports", "jquery", "ln-ember-form-elements/templates/components/form-elements/dropdown-button"], function (_exports, _jquery, _dropdownButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * Renders a toggle button that is basically a checkbox.
   *
   * The component yields two components:
   * * `button`:
   *   The actual button that is always visible. See FormElements.StyledButton for more information.<br>
   *   In addition the button also has a property `hasDropdown`. If `hasDropdown` is set to true
   *   the button will toggle the dropdown. If set to false it will trigger the action that is passed in.
   * * `dropdown`: The dropdown that gets visible when the dropdown button on the button is pressed.
   *
   * When the content of the dropdown or the button changes the dropdown will automatically close itself.
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{#form-elements/dropdown-button hasDropdown=hasDropdown as |dropdown|}}
   *   {{#dropdown.button
   *     action=(action "doSomething")
   *     hasDropdown=hasDropdown
   *     disabled=content.buttonDisabled
   *   }}
   *     Button Label
   *   {{/dropdown.button}}
   *   {{#dropdown.dropdown}}
   *     Dropdown content
   *   {{/dropdown.dropdown}}
   * {{/form-elements/dropdown-button}}
   * ```
  
   * @class formElements/DropdownButtonComponent
   * @extends @ember/component
   */
  var _default = Ember.Component.extend({
    formElements: Ember.inject.service(),
    layout: _dropdownButton.default,
    classNames: [// Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'dropdown-button-component', 'form-elements--dropdown-button-component'],
    theme: null,

    /**
     * If set to true the dropdown is visible. This also changes when the user is opening the dropdown.
     *
     * @memberof formElements/DropdownButtonComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    dropdownOpen: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('dropdownOpen', false);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)(window).on("click.dropdown-button-".concat(Ember.guidFor(this)), function () {
        Ember.run.scheduleOnce('afterRender', function () {
          if (!_this.get('isDestroyed')) {
            _this.set('dropdownOpen', false);
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(window).off("click.dropdown-button-".concat(Ember.guidFor(this)));
    },
    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('dropdownOpen');
      }
    }
  });

  _exports.default = _default;
});