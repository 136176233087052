define("ln-ember-form-elements/components/form-elements/image-upload", ["exports", "ln-ember-form-elements/templates/components/form-elements/image-upload", "jquery"], function (_exports, _imageUpload, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * ### Example:
   *
   * ```Handlebars
   * {{form-elements/image-upload
   *    files=files
   *    addFiles=(action "addFiles")
   *    removeFile=(action "removeFile")
   *    label="Upload files"
   *  }}
   * ```
   *
   * @class formElements/ImageUploadComponent
   */
  var _default = Ember.Component.extend({
    /**
     * @memberof formElements/ImageUploadComponent
     * @instance
     *
     * @type {Array<(File|Object)>}
     */
    files: null,

    /**
     * @memberof formElements/ImageUploadComponent
     * @instance
     *
     * @type {String}
     */
    label: null,
    layout: _imageUpload.default,
    classNames: [// Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'form-elements-image-upload-component', 'form-elements--image-upload-component'],
    classNameBindings: ['fileObjects.length:has-files:has-no-files', 'label:has-label:has-no-label'],
    fileObjectsCache: null,

    /**
     * @memberof formElements/ImageUploadComponent
     * @instance
     *
     * @type {Function}
     */
    removeFile: function removeFile() {},
    fileObjects: Ember.computed('files.[]', function () {
      var _this = this;

      var files = (this.get('files') || []).map(function (file) {
        return _this.get('fileObjectsCache').find(function (obj) {
          return obj.get('file') === file;
        }) || FileObject.create({
          file: file
        });
      });
      this.set('fileObjectsCache', files);
      return files;
    }),
    init: function init() {
      this._super();

      this.set('fileObjectsCache', []);
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super(), (0, _jquery.default)(this.element).on('drop', function (event) {
        return _this2.onDrop(event);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super(), (0, _jquery.default)(this.element).off('drop');
    },
    actions: {
      removeFile: function removeFile(file) {
        var _this3 = this;

        file.remove().then(function () {
          return _this3.get('removeFile')(file.get('file'));
        });
      },
      clickDummy: function clickDummy() {
        (0, _jquery.default)('.file-input', this.element).click();
        return false;
      }
    },
    onDrop: function onDrop(event) {
      event.preventDefault();

      var _event$dataTransfer = event.dataTransfer,
          _event$dataTransfer2 = _event$dataTransfer === void 0 ? {} : _event$dataTransfer,
          files = _event$dataTransfer2.files;

      if (files) {
        this.triggerAddFiles(files);
      }

      return false;
    },
    change: function change(_ref) {
      var target = _ref.target;

      if (target.className !== 'file-input') {
        return;
      }

      this.triggerAddFiles(target.files);
      (0, _jquery.default)(target, this.element).val('');
    },
    triggerAddFiles: function triggerAddFiles(files) {
      files = Array.prototype.filter.call(files, function (_ref2) {
        var type = _ref2.type;
        return type.match(/^image.*/);
      });

      this._compatibleSendAction('addFiles', files);
    },
    _compatibleSendAction: function _compatibleSendAction() {
      var action = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'action';

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (typeof this.get(action) === 'string') {
        return this.sendAction.apply(this, [action].concat(args));
      } else if (typeof this.get(action) === 'function') {
        return this.get(action).apply(void 0, args);
      }
    }
  });

  _exports.default = _default;
  var FileObject = Ember.Object.extend({
    isFile: false,
    id: Ember.computed(function () {
      return Ember.guidFor(this);
    }),
    style: Ember.computed('url', function () {
      var url = this.get('url');
      return url && Ember.String.htmlSafe("background-image: url(".concat(url, ");"));
    }),
    init: function init() {
      var _this4 = this;

      this._super();

      if (this.get('file') instanceof File) {
        if (!this.get('file.type').match(/^image.*/)) {
          return;
        }

        this.set('isFile', true);
        var reader = new FileReader();

        reader.onload = function (_ref3) {
          var result = _ref3.target.result;
          return _this4.set('url', result);
        };

        reader.readAsDataURL(this.get('file'));
      } else {
        this.set('url', this.get('file.preview_url'));
      }
    },
    remove: function remove() {
      var _this5 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this5.set('isRemoved', true);

        Ember.run.later(resolve, 310);
      });
    }
  });
});